import PropTypes from "prop-types";
import React from "react";

// mui-material
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// state management
import { useSelector } from "react-redux";

// third-party
import { BrowserView, MobileView } from "react-device-detect";

// project imports
import { drawerWidth } from "themes/constants";
import { menuItems, otherItems, staffItems } from "menu-items";
import MenuList from "layout/MainLayout/Sidebar/MenuList";

// constants
const PREFIX = "Sidebar";

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
};

const Root = styled("nav")(({ theme }) => ({
  [`&.${classes.drawer}`]: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    // borderRight: "none",
    [theme.breakpoints.up("md")]: {
      top: "100px",
      height: "calc(100% - 100px)",
    },
  },
}));

//-----------------------|| SIDEBAR DRAWER ||-----------------------//

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const { currentAdmin } = useSelector((state) => state.authentication);

  const getSidebarMenuList = () => {
    if (
      currentAdmin &&
      currentAdmin.franchisee &&
      !currentAdmin.franchisee.createdBy
    ) {
      if (currentAdmin && currentAdmin.franchisee.totalStore > 0) {
        return menuItems;
      }
      return otherItems;
    } else {
      if (
        currentAdmin &&
        currentAdmin.franchisee &&
        currentAdmin.franchisee.totalStore > 0
      ) {
        return staffItems;
      }
      return otherItems;
    }
  };

  const drawer = (
    <React.Fragment>
      <BrowserView>
        <MenuList menus={getSidebarMenuList()} />
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList menus={getSidebarMenuList()} />
        </Box>
      </MobileView>
    </React.Fragment>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Root className={classes.drawer} aria-label="main menu">
      <Drawer
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Root>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
