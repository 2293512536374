import React, { lazy } from "react";
import { Routes, Route } from "react-router";
// project imports
import GuestGuard from "utils/route-guard/GuestGuard";
import MinimalLayout from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import Loadable from "ui-component/Loadable";

// login routing
const AuthLogin = Loadable(lazy(() => import("views/authentication/login")));
// const ForgotPassword = Loadable(
//   lazy(() => import("views/authentication/forgot-password"))
// );
//-----------------------|| AUTH ROUTING ||-----------------------//

function LoginRoutes() {
  return (
    <MinimalLayout>
      <Routes>
        <Route element={<NavMotion />}>
          <Route element={<GuestGuard />}>
            <Route path="login" element={<AuthLogin />} />
            {/* <Route path="forgot-password" element={<ForgotPassword />} /> */}
            {/* <Route path="*" element={<Navigate to="../login" />} /> */}
          </Route>
        </Route>
      </Routes>
    </MinimalLayout>
  );
}

export default LoginRoutes;
