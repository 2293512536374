// mui-icons
import {
  StorefrontOutlined as IconMyStores,
  NewspaperOutlined as IconNews,
} from "@mui/icons-material";

// constants
import { routeUrls } from "routes/Paths";

//NOTE: URL and id of the children should have the same name
export const staff = {
  id: "staff",
  type: "group",
  children: [
    {
      id: "my-stores",
      title: "My Stores",
      type: "item",
      url: routeUrls.MyStores,
      icon: IconMyStores,
      breadcrumbs: false,
    },
    {
      id: "franchisee-news",
      title: "News",
      type: "item",
      url: routeUrls.FranchiseeNews,
      icon: IconNews,
      breadcrumbs: false,
      chip: {
        label: "0",
        color: "primary",
        size: "small",
      },
    },
  ],
};
//NOTE: URL and id of the children should have the same name
