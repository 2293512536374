import React, { lazy } from "react";
import { Navigate, Routes, Route } from "react-router";
import { useSelector } from "react-redux";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import { routePaths } from "routes/Paths";

const LazyUserPreferences = Loadable(
  lazy(() => import("views/app-components/UserPreferences"))
  //lazy(() => import("views/application/users/account-profile/Profile1"))
);

// my stores related components
const MyStoreIndexPage = Loadable(
  lazy(() => import("views/app-components/MyStores/index"))
);
const MyStaffListPage = Loadable(
  lazy(() => import("views/app-components/MyStaff/MyStaffList"))
);
const MyStaffAdd = Loadable(
  lazy(() => import("views/app-components/MyStaff/MyStaffAdd"))
);
const MemberLookup = Loadable(
  lazy(() => import("views/app-components/MemberLookup"))
);

const FranchiseeNewsList = Loadable(
  lazy(() => import("views/app-components/FranchiseeNews"))
);

const FranchiseeNewsDetails = Loadable(
  lazy(() => import("views/app-components/FranchiseeNews/NewsDetails"))
);

const FranchiseePostList = Loadable(
  lazy(() => import("views/app-components/FranchiseePosts"))
);

const FranchiseePostDetails = Loadable(
  lazy(() => import("views/app-components/FranchiseePosts/PostDetails"))
);

// const FranchiseeVideoDetails = Loadable(
//   lazy(() => import("views/app-components/Videos"))
// );

function PageNotFound() {
  return <h1>404 - Page Not Found</h1>;
}

//-----------------------|| MAIN ROUTING ||-----------------------//

function MainRoutes() {
  const { currentAdmin } = useSelector((state) => state.authentication);
  if (!currentAdmin) {
    return null;
  }

  return renderLayout(currentAdmin);
}

function renderLayout(currentUser) {
  //
  const isAdmin =
    currentUser &&
    currentUser.franchisee &&
    currentUser.franchisee.createdBy === "";

  const hasStores =
    currentUser &&
    currentUser.franchisee &&
    currentUser.franchisee.totalStore > 0;

  const isStaff =
    currentUser &&
    currentUser.franchisee &&
    currentUser.franchisee.createdBy !== "";

  console.log("Has Stores : " + hasStores);
  console.log("isAdmin : " + isAdmin);
  console.log("isStaff : " + isStaff);
  return (
    <MainLayout>
      <Routes>
        {/* My stores */}

        {(isAdmin || isStaff) && hasStores ? (
          <Route path={routePaths.MyStores}>
            <Route path="" element={<MyStoreIndexPage />} />
          </Route>
        ) : null}

        {/* My staff */}
        {isAdmin ? (
          <>
            <Route path={routePaths.MyStaff}>
              <Route path="" element={<MyStaffListPage />} />
              <Route path="add" element={<MyStaffAdd />} />
              <Route path="edit/:franchiseeId" element={<MyStaffAdd />} />
            </Route>
          </>
        ) : null}

        {/* Member lookup */}
        {isAdmin ? (
          <Route path={routePaths.MemberLookup}>
            <Route path="" element={<MemberLookup />} />
          </Route>
        ) : null}

        {/* Franchisee news */}
        {isAdmin || isStaff ? (
          <>
            <Route path={routePaths.FranchiseeNews}>
              <Route path="" element={<FranchiseeNewsList />} />
              <Route path="view/:id" element={<FranchiseeNewsDetails />} />
            </Route>
          </>
        ) : null}

        {/* Franchisee content */}
        {isAdmin || isStaff ? (
          <>
            <Route path={routePaths.FranchiseePosts}>
              <Route path="" element={<FranchiseePostList />} />
              <Route path="view/:id" element={<FranchiseePostDetails />} />
            </Route>
          </>
        ) : null}

        {/* Video content */}
        {/* {isAdmin || isStaff ? (
          <>
            <Route path="../videos" element={<FranchiseeVideoDetails />} />
          </>
        ) : null} */}

        {/* My Profile */}
        {isAdmin || isStaff ? (
          <Route
            path={routePaths.UserPreferences}
            element={<LazyUserPreferences />}
          />
        ) : null}
        <Route
          path="/"
          element={<Navigate to={routePaths.MyStores} replace />}
        />
        <Route
          path="/login"
          element={<Navigate to={routePaths.MyStores} replace />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </MainLayout>
  );
}

export default MainRoutes;
