export const SET_LAST_TYPE_FILTER = "SET_LAST_TYPE_FILTER";

export const initialState = {
  lastTypeFilterId: null,
};

//-----------------------|| FILTER REDUCER ||-----------------------//

const franchiseePostsFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAST_TYPE_FILTER:
      return {
        ...state,
        lastTypeFilterId: action.typeFilterId,
      };
    default:
      return state;
  }
};

export default franchiseePostsFilterReducer;
