import PropTypes from "prop-types";
import React from "react";
import { useLocation, useNavigate } from "react-router";

// mui-material
import { Box, Button, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

// mui-icons
import { Menu as IconMenu, Add as IconPlus } from "@mui/icons-material";

// state management
import { useSelector } from "react-redux";

// project imports
import { anonymousRoutes } from "routes/AnonymousRoutes";
import { cignallThemeColor } from "themes/constants";
import LogoSection from "../LogoSection";
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";

// style constant
const DivGrow = styled("div")(() => ({
  flexGrow: 1,
}));

const ButtonButtonBackground = styled(Button)(() => ({
  backgroundColor: cignallThemeColor,
  color: "black",
  marginRight: "16px",
  width: "125px",

  "&:hover": {
    background: cignallThemeColor,
  },
}));

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = ({ drawerToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigation = useSelector((state) => state.navigation);

  const customizeDisplayAddButton = () => {
    switch (navigation.isOpen[0].id) {
      case "franchisee-news":
      case "franchisee-posts":
      case "member-lookup":
      case "my-stores":
        return false;
      default:
        return true;
    }
  };

  const displayHeaderAddButton = () => {
    const path = location.pathname;
    if (
      !anonymousRoutes.includes(path) &&
      parseInt(path.toString().split("/").length) <= 2 &&
      customizeDisplayAddButton()
    ) {
      return (
        <React.Fragment>
          <ButtonButtonBackground
            variant="contained"
            onClick={() => navigate(`${navigation.isOpen[0].url}/add`)}
          >
            <IconPlus /> <Typography variant="span">Add</Typography>
          </ButtonButtonBackground>
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      {/* toggle button */}
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={drawerToggle}
      >
        <IconMenu />
      </IconButton>
      {/* logo */}
      <LogoSection />

      <DivGrow />
      {navigation.isOpen.length > 0 && displayHeaderAddButton()}

      <ProfileSection />

      {/* mobile header */}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <MobileSection />
      </Box>
    </React.Fragment>
  );
};

Header.propTypes = {
  drawerToggle: PropTypes.func,
};

export default Header;
