export const routePaths = {
  FranchiseeNews: "franchisee-news",
  FranchiseePosts: "franchisee-posts",
  MemberLookup: "member-lookup",
  MyStaff: "my-staff",
  MyStores: "my-stores",
  UserPreferences: "user-preferences",
};

export const routeUrls = {
  FranchiseeNews: "/franchisee-news",
  FranchiseePosts: "/franchisee-posts",
  MemberLookup: "/member-lookup",
  MyStaff: "/my-staff",
  MyStores: "/my-stores",
  UserPreferences: "/user-preferences",
};
