import { SNACKBAR_OPEN } from "./navigationActions";
import qs from "query-string";
import { fetchPost, saveAuthToken, getAuthToken } from "utils/fetch-webapi";
import { isAnonymousRoute } from "routes/AnonymousRoutes";
import env_config from "../env_config";
import { routeUrls } from "routes/Paths";

const requestCurrentFranchiseeAdmin = "REQUEST_CURRENT_FRANCHISEE_ADMIN";
export const responseCurrentFranchiseeAdmin =
  "RESPONSE_CURRENT_FRANCHISEE_ADMIN";
const requestAdminLogin = "REQUEST_ADMIN_LOGIN";
const responseAdminLogin = "RESPONSE_ADMIN_LOGIN";
const requestForgotPassword = "REQUEST_FORGOT_PASSWORD";
const responseForgotPassword = "RESPONSE_FORGOT_PASSWORD";
const requestChangePassword = "REQUEST_CHANGE_PASSWORD";
const responseChangePassword = "RESPONSE_CHANGE_PASSWORD";
export const updateRequireAttention = "UPDATE_REQUIRE_ATTENTION";

export const actionCreators = {
  // calling /me
  requestCurrentAdmin: (location, navigate) => async (dispatch) => {
    dispatch({ type: requestCurrentFranchiseeAdmin });

    let response = await fetch(`${env_config.API_DOMAIN}/me`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
    });

    if (!response.ok && !isAnonymousRoute(location.pathname)) {
      navigate(`/login?redirect=${location.pathname}`, {
        replace: true,
      });
    }

    if (response.status === 200) {
      response = await response.json();
      dispatch({
        type: updateRequireAttention,
        payload: response.requireAttentionCount,
      });
    } else {
      response = "Unknown";
    }
    dispatch({ type: responseCurrentFranchiseeAdmin, payload: response });
  },
  // calling /authenticate
  requestFranchiseeAdminLogin:
    (location, navigate, values, setSubmitting) => async (dispatch) => {
      try {
        dispatch({ type: requestCurrentFranchiseeAdmin });
        const [, response] = await fetchPost("/franchisee-authenticate", {
          ...values,
        });
        if (response && response.errorNo > 0) {
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: response.message,
            variant: "alert",
            alertSeverity: "error",
          });
        } else {
          saveAuthToken(response.authToken);
          dispatch(actionCreators.requestCurrentAdmin(location, navigate));
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: "Login Success",
            variant: "alert",
            alertSeverity: "success",
          });
          setTimeout(() => {
            if (location && location.search) {
              const val = qs.parse(location.search);
              if (val && val.redirect) {
                window.location.href = window.location.origin + val.redirect;
                return;
              }
            }
            navigate(routeUrls.MyStores, { replace: true });
          }, 500);
        }
        dispatch({ type: responseCurrentFranchiseeAdmin, payload: response });
        setSubmitting(false);
      } catch (error) {
        console.log({ error });
        setSubmitting(false);
      }
    },

  forgotPassword: (email, setSubmitting) => async (dispatch) => {
    try {
      const [, response] = await fetchPost("/forgot-password", { email });
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
      });
      if (setSubmitting) setSubmitting(false);
    } catch (e) {
      if (setSubmitting) setSubmitting(false);
    }
  },

  changePassword: (values, setSubmitting, resetForm) => async (dispatch) => {
    try {
      const [, response] = await fetchPost("/change-password", {
        ...values,
      });

      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: response.errorNo > 0 ? "error" : "success",
      });
      if (response && response.errorNo === 0) {
        resetForm();
        setSubmitting(false);
      }
    } catch (e) {
      setSubmitting(false);
    }
  },
};

const initialState = {
  requestLogin: false,
  users: null,
  gettingCurrentAdmin: false,
  currentAdmin: null,
  requestingForgotPassword: false,
  forgotPasswordResponse: null,
  requestingChangePassword: false,
  requireAttentionCount: 0,
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case requestCurrentFranchiseeAdmin:
      return { ...state, gettingCurrentAdmin: true };
    case responseCurrentFranchiseeAdmin:
      return { ...state, gettingCurrentAdmin: false, currentAdmin: payload };
    case requestAdminLogin:
      return { ...state, requestLogin: true };
    case responseAdminLogin:
      return { ...state, requestLogin: false, users: payload };
    case requestForgotPassword:
      return {
        ...state,
        requestingForgotPassword: true,
        forgotPasswordResponse: null,
      };
    case responseForgotPassword:
      return {
        ...state,
        requestingForgotPassword: false,
        forgotPasswordResponse: payload,
      };
    case requestChangePassword:
      return {
        ...state,
        requestingChangePassword: true,
      };
    case responseChangePassword:
      return {
        ...state,
        requestingChangePassword: false,
      };
    case updateRequireAttention:
      return {
        ...state,
        requireAttentionCount: payload,
      };
    default:
      return state;
  }
};
